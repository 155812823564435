<template>
    <div>
        <h3>Templates</h3>
        <hr>
        <b-button @click="addCompany" variant="outline-success">
            <b-icon icon="plus-circle"></b-icon> Add
        </b-button>
        <hr>
        <b-table class="text-center"
                 striped
                 bordered
                 small
                 hover
                 sticky-header="true"
                 head-variant="light"
                 selectable
                 select-mode="single"
                 responsive="sm"
                 :items="items"
                 :fields="fields"
                 @row-selected="onRowSelected"
        >
            <template #cell(edit)="row">
                <b-icon-pencil-fill @click="editItem(row.index)"></b-icon-pencil-fill>
            </template>
            <template #cell(delete)="row">
                <b-icon-trash-fill @click="delItem(row.index)"></b-icon-trash-fill>
            </template>
        </b-table>
        <b-modal ref="confirm-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Delete Template: {{selTempName}} ?</h5>
            </div>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="danger" block @click="deleteYes">Yes (No Undo)</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="deleteNo">No</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
        <b-modal
            ref="add-comp-modal"
            hide-footer
            centered
            title="Add Company"
        >
            <div>
                <b-container >
                    <b-form @submit="compOK">
                        <b-form-group>
                            <label class="m-25" for="sys-addcomp">Name:</label>
                            <b-form-input
                                class="m-75"
                                id="sys-addcomp"
                                type="text"
                                v-model="form.name"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <hr>
                        <b-container class="text-center">
                            <b-row cols="2">
                                <b-col>
                                    <b-button variant="success" type="submit" block >OK</b-button>
                                </b-col>
                                <b-col>
                                    <b-button variant="warning" block @click="compCancel">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-form>
                </b-container>
            </div>
        </b-modal>
        <b-modal
            ref="edit-comp-modal"
            hide-footer
            centered
            title="Edit Name"
        >
            <div>
                <b-container >
                    <b-form @submit="compEditOK">
                        <b-form-group>
                            <label class="m-25" for="sys-edit-comp-old">Old Name:</label>
                            <b-form-input
                                readonly
                                class="m-75"
                                id="sys-edit-comp-old"
                                type="text"
                                :placeholder="selTempName"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <label class="m-25" for="sys-editcomp">New Name:</label>
                            <b-form-input
                                class="m-75"
                                id="sys-editcomp"
                                type="text"
                                v-model="form.name"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <hr>
                        <b-container class="text-center">
                            <b-row cols="2">
                                <b-col>
                                    <b-button variant="success" type="submit" block >OK</b-button>
                                </b-col>
                                <b-col>
                                    <b-button variant="warning" block @click="compEditCancel">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-form>
                </b-container>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "CompanyEditor",
    data() {
        return {
            fields: [
                {key: 'name', label: 'Name'},
                {key: 'edit', label: 'Edit'},
                {key: 'delete', label: 'Delete'}
            ],
            selected: [],
            form: {
                name: ''
            },
            itemSelIdx: 0
        }
    },
    created() {
        this.$store.dispatch('getCompList');
    },
    mounted() {

    },
    methods: {
        onRowSelected(items) {
            this.selected = items
        },
        addCompany() {
            this.$refs['add-comp-modal'].show();
        },
        editItem(itemIndex) {
            this.itemSelIdx = itemIndex;
            this.form.name = '';
            this.$refs['edit-comp-modal'].show();
        },
        delItem(itemIndex) {
            this.itemSelIdx = itemIndex;
            this.$refs['confirm-modal'].show();
        },
        compOK(event) {
            event.preventDefault();
            this.$refs['add-comp-modal'].hide();
            this.$store.dispatch('addCompList', {
                name: this.form.name
            }).then(() => {
                this.$bvToast.toast('Company Added', {
                    title: 'Add Company',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getCompList');
            }).catch(() => {
                this.$bvToast.toast('Add Company Error', {
                    title: 'Add Company',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        compCancel() {
            this.$refs['add-comp-modal'].hide();
        },
        compEditOK(event) {
            event.preventDefault();
            this.$refs['edit-comp-modal'].hide();
            this.$store.dispatch('editCompList', {
                oldname: this.selected[0].name,
                newname: this.form.name
            }).then(() => {
                this.$bvToast.toast('Company Edit', {
                    title: 'Edited Company',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getCompList');
            }).catch(() => {
                this.$bvToast.toast('Edit Company Error', {
                    title: 'Edit Company',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        compEditCancel() {
            this.$refs['edit-comp-modal'].hide();
        },
        deleteYes() {
            this.$refs['confirm-modal'].hide();
            this.$store.dispatch('deleteCompany', this.selected[0].name).then(() => {
                this.$bvToast.toast('Company Deleted', {
                    title: 'Delete Company',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getCompList');
            }).catch(() => {
                this.$bvToast.toast('Delete Company Error', {
                    title: 'Delete Company',
                    variant: 'danger',
                    solid: true
                });
            })
        },
        deleteNo() {
            this.$refs['confirm-modal'].hide();
        }
    },
    computed: {
        items() {
            let fmtList = [];
            if (this.$store.getters.compList !== null) {
                const compRef = this.$store.getters.compList;
                for (let i = 0; i < compRef.length; i++) {
                    if (compRef[i].active) {
                        let cItem = {name: compRef[i].name};
                        fmtList.push(cItem);
                    }
                }
            }
            return fmtList;
        },
        selTempName() {
            if (this.selected.length !== 0) {
                return this.selected[0].name
            }
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
